@use "../scss//variables.scss" as vars;
@use "../scss/mixins" as mixins;

.wrapper {
  height: "40vh";
  border-radius: "20px";
  border: "1px solid red";
}
.backgroundWrapper {
  height: 80%;
  width: 100%;
  border-radius: 20px;
  //   border-bottom-left-radius: 20px;
  //   border-bottom-right-radius: 20px;
  z-index: 0;
  position: relative;
  //   border: 1px solid blue;
  img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 0;
  }
}

.previewImg {
  height: 90px;
  width: 90px;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 10px;
}

.removeButton {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-danger;
  color: vars.$color-light;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten(vars.$color-danger, 20%) !important;
  }
}


.uploadLogoContainer {
    height: 120px;
  
    .dropzone {
      border: 1px dashed vars.$color-dark;
      border-radius: 8px;
      margin: 0;
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }
  }