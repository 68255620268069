@use './variables' as vars;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@300;400;700&display=swap');

.bold-title-100 {
  font-family: vars.$font-heading;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xl;
  line-height: 100%;
}

.bold-title {
  font-family: vars.$font-heading;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xl;
  line-height: 100%;
}

.bold-title-helvetica {
  font-family: vars.$font-helvetica-bold;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xl;
  line-height: 100%;
}

.light-title {
  font-family: vars.$font-heading;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 90%;
  letter-spacing: normal;
  font-size: vars.$font-size-l;
}

.small-title{
  font-family: vars.$font-helvetica;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 90%;
  letter-spacing: normal;
  font-size: vars.$font-size-l;
}

.sub-title {
  font-family: vars.$font-heading;
  font-weight: 700;
  font-stretch: normal;
  // transform: translateY(-7px);
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-m;
}
.sub-title-ml {
  font-family: vars.$font-heading;
  font-weight: 700;
  font-stretch: normal;
  // transform: translateY(-7px);
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-ml;
}

.sub-title-brief {
  font-family: vars.$font-body;
  font-weight: 700;
  font-stretch: normal;
  // transform: translateY(-7px);
  font-style: bold;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-m;
}

.sub-title-editable {
  font-family: vars.$font-body;
  font-weight: 700;
  font-stretch: normal;
  // transform: translateY(-7px);
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 2rem;
}

.bigger-text-bold {
  font-family: vars.$font-body;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-l;
}

.bigger-text {
  font-family: vars.$font-body;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-l;
}

.text-xxl {
  font-family: vars.$font-body;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 98%;
  letter-spacing: normal;
  font-size: vars.$font-size-m;
}

.text-xl {
  font-family: vars.$font-body;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 120%;
  letter-spacing: normal;
}

.text {
  font-family: vars.$font-body;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xs;
}

.text-16 {
  font-family: vars.$font-body;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xs-16;
}

.text-s {
  font-family: vars.$font-body;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xxs;
}

.sub-title-helvetica {
  font-family: vars.$font-helvetica-bold;
  font-weight: 700;
  font-stretch: normal;
  // transform: translateY(-7px);
  font-style: bold;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-m;
}

.text-helvetica {
  font-family: vars.$font-helvetica;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xs;
}

.text-16-helvetica {
  font-family: vars.$font-helvetica;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xs-16;
}
